// Remove and replace to the root path when we will remove landing from root
export const MAIN_PAGE_ROUTE = '/';

export enum MainRoutes {
  edu = '/edu',
  weber = '/weber',
  wiz = '/wiz',
  bid = '/bid',
  auth = '/auth',
  support = '/support',
  students_landing = '/students',
  wiz_landing = '/wiz_land',
}

export enum EduRoutes {
  courses = '/courses',
  course = '/courses/course',
  enroll = '/courses/course/enroll',

  'digital-passes' = '/digital-passes',
  nft = '/digital-passes/nft',
  shcool = '/shcool',
  shcools = '/shcools',
  classes = '/classes',
  progress = '/digital-passes/learning-progress',

  verify = '/verify/courses',
  'my-courses' = '/my-courses',
  edit = '/my-courses/course/edit',
  learners = '/learners',
  visual = '/visual',
  'course-content' = '/course-content',
  'upload-content' = '/upload-content',
  preview = '/my-courses/course/preview',
  performance = '/my-courses/course/performance',
  rejected = '/my-courses/course/rejected',
  stream = '/stream',
}

export enum WeberRoutes {
  feeds = '/feeds',
  feed = '/feeds/feed',
  favorites = '/feeds/favorites',
  'feed-create-attachments' = '/feeds/feed/create/attachments',
  'feed-create-link' = '/feeds/feed/create/link',
  'feed-create-article' = '/feeds/feed/create/article',
  'feed-edit' = '/feeds/feed/edit',

  communities = '/communities',
  community = '/communities/community',
  'community-create' = '/communities/community/create',
  'community-edit' = '/communities/community/edit',
}

export enum WizRoutes {
  quiz = '/quiz',
  quizzes = '/quizzes',
  performance = '/performance',
  scoreboard = '/scoreboard',
}

export enum AuctionRoutes {
  actual = '/actual',
  about = '/about',
  winnings = '/winnings',
  auctionView = '/actual/view',
  winningsView = '/winnings/view',
}

export enum AuthRoutes {
  signin = '/sign-in',
  signup = '/sign-up',
  reset = '/reset-password',
  change = '/change-password',
  code = '/check-code',
  'google-signin-signup' = '/google-callback-signin',
  'apple-signin-signup' = '/apple-oauth-submit',
  profile = '/profile',
}

export enum ProfileRoutes {
  user = '/user',
  me = '/me',
  edit = '/edit',
  chat = '/chat',
  networking = '/networking',
  wallet = '/wallet',
}

export type AppRoutes =
  | '/'
  | `${MainRoutes}`
  | `${MainRoutes.edu}${EduRoutes}`
  | `${MainRoutes.weber}${WeberRoutes}`
  | `${MainRoutes.wiz}${WizRoutes}`
  | `${MainRoutes.auth}${AuthRoutes}`
  | `${MainRoutes.auth}${AuthRoutes.profile}${ProfileRoutes}`;
