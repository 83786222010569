import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  checkEnrolledUserCourse,
  enrollUserCourse,
  setPrivateCoursePurchaseV3,
} from '../../../api/course';
import { getPublicCourseIdV3 } from '../../../api/courseV3';
import { saveCurrentUrl } from '../../../api/sessions';
import { Bread, Breadcrumbs } from '../../../components/Breadcrumbs';
import { Loader } from '../../../components/Loader';
import { MediaViewer } from '../../../components/MediaViewer/MediaViewer';
import { sendCoursePurchase, sendSpendEDRES } from '../../../hooks/useAnalytics';
import useFetch from '../../../hooks/useFetch';
import { useNavigate } from '../../../hooks/useNavigate';
import { Link } from '../../../horizon-components/Link';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';
import { useRewardContext } from '../../../providers/RewardProvider';
import { useTitleBackContext } from '../../../providers/TitleBackProvider/TitleBackProvider';
import getErrorMessages from '../../../utils/getErrorMessages';
import { getUserData } from '../../../utils/getUserData';
import { EarnForEnd } from '../../../widgets/EarnForEnd';
import { NoAvatar } from '../../../widgets/NoAvatar';
import { CourseCount } from './components/CourseCount';
import { CourseDigitalPass } from './components/CourseDigitalPass';
import CourseInfo from './components/CourseInfo';
import CourseUnitsView from './components/CourseUnitsView';
import './CoursePreview.scss';
import SEO from '../../../components/SEO/SEO';
import { clearStringFromHtmlTags } from '../../../utils/clearStringFromHtmlTags';
import { Stream } from '@cloudflare/stream-react';

export const CoursePreview = () => {
  const { id } = useParams();
  const { user, updateWallet } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [breads, setBreads] = useState<Bread[]>([]);
  const {
    data: course,
    isLoading,
    fetchData: fetchCourseId,
  } = useFetch((course_id: number) => getPublicCourseIdV3(course_id));
  const { useTitleBack } = useTitleBackContext();
  const [amount, setAmount] = useState(0);
  const { rewards } = useRewardContext();

  useEffect(() => {
    if (rewards?.length) {
      const value = rewards?.find(
        (item) => item.tokenomic_type === 'course_purchase'
      )?.value;

      if (value) setAmount(parseFloat(value));
    }
  }, [rewards, t]);

  useEffect(() => {
    if (id) {
      fetchCourseId(typeof id === 'string' ? parseInt(id) : 0);
    }
  }, [id, t]);

  useEffect(() => {
    if (course?.id) {
      setBreads([
        { title: 'EDU', url: MainRoutes.edu },
        {
          title: t('course.allCourses'),
          url: MainRoutes.edu + EduRoutes.courses,
        },
        {
          title: course.subject.title,
          url:
            MainRoutes.edu + EduRoutes.courses + `/?subject=${course.subject.slug}`,
        },
        {
          title: course.title,
          url: MainRoutes.edu + EduRoutes.course + `/${id}`,
        },
      ]);
    }
  }, [course, id, t]);

  const userData = getUserData(course?.owner);
  const introductionFile = course?.introduction_files?.[0];
  const cfVideoGuid =
    course?.cf_video_stream?.cf_signed_video_guid ?? course?.cf_video_stream?.cf_uid;

  const checkEnroll = useCallback(() => {
    checkEnrolledUserCourse(Number(id))
      .then((data) => {
        setIsEnrolled(data.is_enrolled);
      })
      .catch(() => {
        setIsEnrolled(false);
      });
  }, [id]);

  useEffect(() => {
    const courseId = Number(id);

    if (!id || Number.isNaN(courseId)) {
      navigate(MainRoutes.edu + EduRoutes.courses);
      return;
    }
  }, [id]);

  useEffect(() => {
    user?.guid && checkEnroll();
  }, [user?.guid]);

  // TODO set value and course name for sendSpendEDRES
  const enrollCourse = () => {
    setIsPurchased(true);
    return setPrivateCoursePurchaseV3(Number(id || 0))
      .then(() =>
        enrollUserCourse(Number(id || 0))
          .then(() => {
            sendCoursePurchase();
            sendSpendEDRES();
            updateWallet();
            navigate(MainRoutes.edu + EduRoutes.enroll + `/${id}`);
          })
          .catch((error) => {
            toast(getErrorMessages(error?.response?.data), {
              type: 'error',
              position: 'top-center',
            });
          })
      )
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
          position: 'top-center',
        });
      })
      .finally(() => setIsPurchased(false));
  };

  const enrollBtnHandler = () => {
    if (!user) {
      saveCurrentUrl(window.location.pathname);
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    }
    if (!isEnrolled) {
      enrollCourse();
    } else {
      goToCourse();
    }
  };

  const goToCourse = () => navigate(MainRoutes.edu + EduRoutes.enroll + `/${id}`);

  useTitleBack(course?.title);

  return (
    <div className="CoursePreview">
      <SEO
        title={course?.title}
        desc={clearStringFromHtmlTags(course?.description)}
        bannerUrl={course?.cover_image}
        loading={!course}
      />

      <Breadcrumbs breads={breads} className="hidden md:block" />

      {isLoading ? (
        <Loader />
      ) : (
        !!course && (
          <div className="CoursePreview__content">
            <div className="CoursePreview__list">
              <div className="CoursePreview__head">
                <div className="CoursePreview__user">
                  <Link
                    to={
                      MainRoutes.auth +
                      AuthRoutes.profile +
                      ProfileRoutes.user +
                      `/${userData?.guid}`
                    }
                  >
                    <NoAvatar src={userData.image} alt={userData.username} />
                  </Link>
                  <div>
                    <h4 className="hidden md:block">{course?.title}</h4>
                    <h4 className="block md:hidden">
                      <Link
                        to={
                          MainRoutes.auth +
                          AuthRoutes.profile +
                          ProfileRoutes.user +
                          `/${userData?.guid}`
                        }
                        className="block md:hidden"
                      >
                        {t('course.By')} {userData.username}
                      </Link>
                    </h4>
                    <div>
                      <span className="md-max:hidden">
                        <Link
                          to={
                            MainRoutes.auth +
                            AuthRoutes.profile +
                            ProfileRoutes.user +
                            `/${userData?.guid}`
                          }
                        >
                          {t('course.By')} {userData.username}
                        </Link>
                      </span>
                      <span className="!font-semibold md-max:hidden">•</span>
                      <span>{moment(course.created_at).format('DD.MM.YY')}</span>
                    </div>
                  </div>
                </div>
                {course.id && (
                  <>
                    {isEnrolled ? (
                      <MButton
                        onClick={goToCourse}
                        color={'primary'}
                        variant={'highlighted'}
                        className="fixed-mobile z-10 sm-max:fixed sm-max:left-2 sm-max:w-[calc(100vw_-_16px)]"
                      >
                        {t('btnsText.continue')}
                      </MButton>
                    ) : (
                      <MButton
                        onClick={() => enrollBtnHandler()}
                        color={'primary'}
                        variant={'highlighted'}
                        className="fixed-mobile z-10 sm-max:fixed sm-max:left-2 sm-max:w-[calc(100vw_-_16px)]"
                        loading={isPurchased}
                        disabled={isPurchased}
                      >
                        {t('nft.buy_for')} {amount || 0} EdRes
                      </MButton>
                    )}
                  </>
                )}
              </div>
              <EarnForEnd />
              <CourseDigitalPass course={course} className="md:!hidden" />
              {cfVideoGuid ? (
                <div className={'overflow-hidden rounded-[20px]'}>
                  <Stream
                    src={cfVideoGuid}
                    width="100%"
                    height="100%"
                    controls
                    key={course.id}
                  />
                </div>
              ) : (
                <MediaViewer
                  link={introductionFile?.file || ''}
                  bg={course.cover_image}
                />
              )}
              {/* Course info */}
              <div className="CoursePreview__info content">
                <CourseInfo course={course} isEnrolled={isEnrolled} />
              </div>
            </div>
            <div className="CoursePreview__aside !hidden md:!flex">
              <CourseDigitalPass course={course} />
              <CourseCount
                rating={parseFloat(course?.rating?.toFixed(1)) || 0}
                enrolls={course?.enrolls_count || 0}
              />
              <CourseUnitsView course={course} />
            </div>
          </div>
        )
      )}
    </div>
  );
};
